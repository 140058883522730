import routeConfig from "./routeConfig";

const appConfig = {
    brand: "Soychi",
    email: "jenny@soychigroup.com",
    phoneNumber: "+84 90 6684620",
    address: "91 Trương Phuoc Phan, Binh Tri Dong Ward, Binh Tan District, Ho Chi Minh City, Vietnam",
    addressMap: "https://maps.app.goo.gl/M8WmkmKmjNqJ58yo7"
}

type HeaderConfig_Type = {
    to: string;
    label: string;
    href?: string;
}

const headerConfig: HeaderConfig_Type[] = [
    {
        to: routeConfig.home,
        label: "Home",
        href: ""
    },
    {
        to: routeConfig.about,
        label: "About us",
        href: ""
    },
    {
        to: routeConfig.product,
        label: "Products",
        href: "#product"
    },
    {
        to: routeConfig.news,
        label: "News",
        href: ""
    },
    {
        to: routeConfig.contact,
        label: "Contact",
        href: ""
    },
]
  
  export {
    appConfig,
    headerConfig
  };
  