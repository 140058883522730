import { AppBar, Box, Container, CssBaseline, Drawer, IconButton, Toolbar, Typography, useTheme, List, ListItem, Divider, ListItemButton, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import PhoneIcon from '@mui/icons-material/Phone';
import routeConfig from "../../../configs/routeConfig";
import { appConfig, headerConfig } from "../../../configs/appConfig";
import images from "../../../assets/images";
import { useState } from "react";



const Header = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();

    // const container = window !== undefined ? () => window().document.body : undefined;

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
      };

    const handleScroll = (href: string) => {
        const element = document.querySelector(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar component="nav" position="static" color="primary">
            <Box
                sx={{
                    bgcolor: "#E8F0EA",
                    minHeight: 40,
                    width: "100%",
                    py: 2,
                    display: "flex",
                    [theme.breakpoints.up('xs')]: {
                        flexDirection: "column",
                        gap: 1,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",

                        px: 4
                    },
                    [theme.breakpoints.up('md')]: {
                        flexDirection: "row",
                        gap: 2,
                        alignItems: "center",
                        px: 30
                    }
                }} 
            >
                {
                    [
                        { label: "Hotline/Whatsapp/Telegram", link: `tel:${appConfig.phoneNumber}`, text: appConfig.phoneNumber  },
                        { label: "Email", link: `https://mail.google.com/mail/?view=cm&fs=1&to=${appConfig.email}`, text: appConfig.email  },

                    ]?.map((item) => 
                    <Typography 
                        key={item.label}
                        sx={{    
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        color: "#54a33b",
                        textDecoration: "none",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        fontSize: 20,
                    }}>
                        <Typography
                                noWrap
                                sx={{
                                    mr: 0.5
                                }}
                            >
                                {item.label}:
                        </Typography>
                        <Typography 
                                    component={Link} to={item.link}
                                    sx={{textDecoration: "none", height: "100%"}}
                                    target="_blank"
                                >
                                    {item.text}
                        </Typography>
                    </Typography>                
          
                    
                )}
            </Box>
            <Container maxWidth="xl" sx={{py: 1}}>
           
                <Toolbar disableGutters sx={{ px: {
                    md: 12
                }}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box
                        component="img"
                        sx={{
                            mr: 1
                        }}
                        src={images.logo}
                        width={80}
                    />
                    {/* <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to={routeConfig.home}
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "Montserrat",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none"
                        }}
                    >
                        {appConfig.brand}
                    </Typography> */}

                    <Box sx={{ display: { xs: "none", md: "flex" }, ml: "auto" }}>
                        {headerConfig.map((item) => {
                            return <Typography
                            onClick={() => {
                                if(item.href) {
                                    handleScroll(item.href)
                                 
                                }
                            }}
                                key={item.label}
                                component={Link}
                                to={`${item.to}${item.href}`}
                                sx={{
                                    mr: 1,
                                    my: 2,
                                    px: 2,
                                    textDecoration: "none",
                                    borderRadius: 10,
                                    fontFamily: "Montserrat",
                                    fontWeight: 700,
                                    fontSize: 25,
                                    color: "#54a33b",
                                }}
                            >
                                {item.label}
                            </Typography>

                        }

                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

        <Box component="nav">
        <Drawer
        //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { md: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 200 }
          }}
        >
                <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ my: 2, mr: "auto" }}>
        Medre
      </Typography>
      <Divider />
      <List>
        {headerConfig.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText>
                <Box
                  key={item.label}
                  component={Link}
                  to={item.to}
                  sx={{ my: 0, px: 2, display: "block", textDecoration: "none", color: "inherit" }}
                >
                  {item.label}
                </Box>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
          
        </Drawer>
      </Box>
    </Box>
}

export default Header;