import { ProductGroup } from "./interfaces";

const freshFruitGroup: ProductGroup = {
    title: "Fresh Fruits",
    products: [

        {
            id: 7,
            title: "Cashew Nut",
            description: "",
            // img: "/assets/images/products/mango.png"
            img: "https://i.ibb.co/pjX8qyrM/cashew-nut.png"
            
        
        },

        {
            id: 8,
            title: "Macadamia",
            description: "",
            // img: "/assets/images/products/mango.png"
            img: "https://i.ibb.co/NghKW9ZM/cashew-nut.png"
            
        
        },

        {
            id: 5,
            title: "Dried Dragon Fruit",
            description: "",
            // img: "/assets/images/products/mango.png"
            img: "https://i.ibb.co/707xTTY/dried-dragon-fruit.jpg"
            
        
        },
  
        {
            id: 1,
            title: "Coconut",
            description: "",
            // img: "/assets/images/products/coconut.png"
            img: "https://i.ibb.co/GpCqsRf/coconut.jpg"
        },

        {
            id: 2,
            title: "Lemongrass",
            description: "",
            // img: "/assets/images/products/lemongrass.png"
            img: "https://i.ibb.co/bBGjTPg/lemongrass.png"
            
        },
        {
            id: 3,
            title: "Lime",
            description: "",
            // img: "/assets/images/products/lime.png"
            img: "https://i.ibb.co/1TQ6SHn/lime.png"
        },
        {
            id: 4,
            title: "Mango",
            description: "",
            // img: "/assets/images/products/mango.png"
            img: "https://i.ibb.co/C2SmHtw/mango.png"
            
        
        },


        






        

        




        {
            id: 6,
            title: "Dried Mango",
            description: "",
            // img: "/assets/images/products/mango.png"
            img: "https://i.ibb.co/671ZnjDq/dried-mango.jpg"
            
        
        },






    ]
    
}

export {
    freshFruitGroup
}






