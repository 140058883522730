import { Container, Typography } from "@mui/material";

const AboutPage = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ color: "#54a33b", fontWeight: 'bold', textAlign: 'center', fontSize: 30, mb: 4,  }}>
                Soychi Corp: Built on trust, driven by quality
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'justify', mb: 4 }}>
            At Soychi Global, we believe in building lasting partnerships based on trust and delivering excellence in every transaction. With over a decade of experience in import-export and trade finance, our founders bring deep industry expertise and a strong commitment to sustainable trade. Driven by quality and reliability, we specialize in sourcing premium agricultural products from Vietnam for global markets, ensuring that our clients receive ethically sourced, top-quality goods.


            </Typography>
        </Container>
    );
};
export default AboutPage;